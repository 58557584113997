import React from 'react';
import PropTypes from 'prop-types';
import { wrapper } from 'components/base/dialog';
import Errors from 'components/base/errors';
import { getLinkValidateErrorMessage } from 'utils/get_link_validate_error_message';
import Textarea from 'components/base/textarea';

class UpdateFieldDialog extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    var data = this.props.data;
    var state = {
      errors: [],
      allow_to_skip: data.allow_to_skip,
      lookup_display_field: data.lookup_display_field,
      use_default: data.use_default,
      default_value: data.default_value
        ? data.default_value
        : this.getInitialDefaultValue(),
      check_use_default: data.use_default !== 'unused',
      additional_explanation: data.additional_explanation
        ? data.additional_explanation
        : '',
    };
    this.state = state;
  }

  onAllowToSkipChange = (e) => {
    var state = { allow_to_skip: !$(e.target).prop('checked') };
    this.setState(state);
  };

  onChangeDefaultValueCheckBox = (e) => {
    let checkUseDefault = $(e.target).prop('checked');
    let useDefault;
    if (checkUseDefault) {
      useDefault = 'manual';
    } else {
      useDefault = 'unused';
    }
    this.setState({
      check_use_default: checkUseDefault,
      use_default: useDefault,
    });
  };

  onChangeValueInput = (e, key) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  getInitialDefaultValue = () => {
    const data = this.props.data;
    if (
      ['DROP_DOWN', 'RADIO_BUTTON', 'CHECK_BOX', 'MULTI_SELECT'].indexOf(
        data.field_type,
      ) >= 0
    ) {
      const options = JSON.parse(data.options);
      return options[0];
    } else {
      return '';
    }
  };

  getLookupDisplayFieldsList = () => {
    return (
      <div className="form-group col-md-6" id="lookup-display-value">
        <select
          className={`form-control`}
          id="lookup-display-value-select"
          defaultValue={this.state.lookup_display_field}
          onChange={(e) => {
            this.onChangeValueInput(e, 'lookup_display_field');
          }}
        >
          {this.props
            .getLookupDisplayFields(this.props.field_option)
            .map((field, idx) => {
              return (
                <option key={idx} value={field.field_code}>
                  {field.field_label}
                </option>
              );
            })}
        </select>
      </div>
    );
  };

  getInputDefaultValueField = () => {
    if (!this.state.use_default) {
      return null;
    }
    const data = this.props.data;
    // DROP_DONN RADIO_BUTTON CHECK_BOX MULTI_SELECT は例外的に処理
    if (
      ['DROP_DOWN', 'RADIO_BUTTON', 'CHECK_BOX', 'MULTI_SELECT'].indexOf(
        data.field_type,
      ) >= 0
    ) {
      const options = JSON.parse(data.options);
      return (
        <div className="form-group col-md-6">
          <select
            className={`form-control`}
            id="default-value-select"
            defaultValue={this.state.default_value}
            onChange={(e) => {
              this.onChangeValueInput(e, 'default_value');
            }}
          >
            {options.map((o, idx) => {
              return (
                <option key={idx} value={o}>
                  {o}
                </option>
              );
            })}
          </select>
        </div>
      );
    }
    let type;
    if (data.field_type === 'NUMBER') {
      type = 'number';
    } else if (data.field_type === 'TIME') {
      type = 'time';
    } else if (data.field_type === 'DATE') {
      type = 'date';
    } else if (data.field_type === 'DATETIME') {
      type = 'datetime-local';
    } else {
      type = 'text';
    }
    return (
      <div className="form-group col-md-6">
        <input
          type={type}
          className={'form-control'}
          id="default-value-input"
          value={this.state.default_value}
          onChange={(e) => {
            this.onChangeValueInput(e, 'default_value');
          }}
          maxLength={256}
        />
      </div>
    );
  };

  getDefaultValueOptions = (fieldType) => {
    if (fieldType === 'SINGLE_LINE_TEXT') {
      return (
        <div className="form-row">
          <div className="form-group col-md-6">
            <select
              className="form-control"
              id="default_value_type"
              onChange={(e) => this.onChangeValueInput(e, 'use_default')}
              value={this.state.use_default || ''}
            >
              <option value="manual">手動入力</option>
              <option value="user_name">氏名を自動入力</option>
              <option value="user_mail">メールアドレスを自動入力</option>
              <option value="login_id">ログインIDを自動入力</option>
              <option value="external_key">連携キーを自動入力</option>
            </select>
          </div>
          {this.state.use_default === 'manual'
            ? this.getInputDefaultValueField()
            : null}
        </div>
      );
    } else if (['DATE', 'TIME', 'DATETIME'].includes(fieldType)) {
      return (
        <div className="form-row">
          <div className="form-group col-md-6">
            <select
              className="form-control"
              id="default_value_type"
              onChange={(e) => this.onChangeValueInput(e, 'use_default')}
              value={this.state.use_default || ''}
            >
              <option value="manual">手動入力</option>
              <option value="current_datetime">日時を自動入力</option>
            </select>
          </div>
          {this.state.use_default === 'manual'
            ? this.getInputDefaultValueField()
            : null}
        </div>
      );
    } else {
      return <div className="row">{this.getInputDefaultValueField()}</div>;
    }
  };

  render = () => {
    var err;
    if (this.state.errors) {
      err = (
        <Errors
          errors={this.state.errors}
          onError={(errors) => {
            this.setState({ errors });
          }}
        />
      );
    }

    return (
      <div className="modal-content">
        {this.props.modalHeader({
          title: '更新項目の設定',
        })}
        <div className="modal-body">
          {err}
          <form>
            <div className="row">
              <div className="col-sm-6 form-group">
                <label>フィールド名</label>
                <div className="help-block">{this.props.data.field_label}</div>
              </div>
              <div className="col-sm-6 form-group">
                <label>フィールドタイプ</label>
                <div className="help-block">{this.props.data.field_type}</div>
              </div>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="allow-to-skip"
                value="1"
                checked={!this.state.allow_to_skip}
                onChange={this.onAllowToSkipChange}
              />
              <label className="form-check-label" htmlFor="allow-to-skip">
                必須入力
              </label>
            </div>
            {['FILE', 'CREATOR', 'MODIFIER', 'USER_SELECT'].indexOf(
              this.props.data.field_type,
            ) === -1 ? (
              <>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="use-default"
                    value="1"
                    checked={this.state.check_use_default}
                    onChange={this.onChangeDefaultValueCheckBox}
                  />
                  <label className="form-check-label" htmlFor="use-default">
                    固定の値
                  </label>
                </div>
                {this.state.check_use_default
                  ? this.getDefaultValueOptions(this.props.data.field_type)
                  : null}
              </>
            ) : null}
            {this.props.field_option.lookup ? (
              <>
                <label
                  className="form-check-label"
                  htmlFor="lookup-display-value"
                >
                  表示するルックアップのフィールド
                </label>
                <div className="row">{this.getLookupDisplayFieldsList()}</div>
              </>
            ) : null}
            <label htmlFor="additional-explanation-input">補足説明</label>
            <Textarea
              maxRows={5}
              minRows={1}
              className={'form-control'}
              id="additional-explanation-input"
              value={this.state.additional_explanation}
              onChange={(e) => {
                this.onChangeValueInput(e, 'additional_explanation');
              }}
              maxLength={255}
            />
          </form>
        </div>
        {this.props.modalFooter({
          onOk: this.onOk,
        })}
      </div>
    );
  };

  onOk = () => {
    let invalidErrorTextList = [];
    const additional_explanation = this.state.additional_explanation
      ? this.state.additional_explanation
      : null;
    let defaultValue = null;
    if (
      this.state.use_default &&
      this.state.default_value &&
      this.props.field_option.protocol
    ) {
      // protocolがある場合はLINKフィールドなのでバリデーションをする
      const validateResult = getLinkValidateErrorMessage(
        this.state.default_value,
        this.props.field_option.protocol,
      );
      if (validateResult !== null) {
        invalidErrorTextList.push(validateResult);
      }
    }
    if (this.state.use_default === 'manual') {
      defaultValue = this.state.default_value;
    }
    if (invalidErrorTextList.length > 0) {
      this.setState({
        errors: invalidErrorTextList,
      });
      return;
    }
    this.props.onChange({
      allow_to_skip: this.state.allow_to_skip,
      lookup_display_field: this.state.lookup_display_field,
      additional_explanation: additional_explanation,
      use_default: this.state.use_default,
      default_value: defaultValue,
    });
    this.props.close();
  };

  componentDidMount() {}
}

export default wrapper(UpdateFieldDialog);
